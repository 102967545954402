import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { SectionTitle } from "@hemalr/react-blocks"
import { primaryColor } from "../../site-config"
import Layout from "../components/layout"
import StyledMain from "../components/styled-main"
import SEO from "../components/seo"
import StyledH2 from "../components/styled-h2"

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 2rem 0 3rem auto;
  @media (max-width: 560px) {
    & {
      grid-template-columns: auto;
      justify-content: stretch;
    }
  }
`

const StyledH1 = styled.h1`
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
`

const OurPodiatrists = () => {
  const data = useStaticQuery(graphql`
    query {
      moha: file(relativePath: { eq: "DrMohangiBhatt.png" }) {
        childImageSharp {
          fluid(maxHeight: 769) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      uma: file(relativePath: { eq: "DrUmangiBhatt.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 769) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Our podiatrists"
        href="https://mopodiatry.com.au/our-podiatrists"
      />
      <SectionTitle
        text="Our Podiatrist"
        color={primaryColor}
        level="h1"
        size="3rem"
      />
      <StyledMain>
        <StyledDiv>
          <div>
            <Img
              fluid={data.moha.childImageSharp.fluid}
              alt="Dr Mohangi Bhatt"
            />
          </div>
          <div>
            <StyledH1>Dr Mohangi Bhatt</StyledH1>
            <StyledH2>B. Pod. Med (UWA)</StyledH2>
            <p>
              Graduating from UWA, I have had experience at Royal Perth
              Hospital, Sir Charles Gardner Hospital, Fremantle Hospital and
              Community health centres around Perth.
            </p>
            <p>
              I treat all podiatric complaints, with specific interest in
              treating ingrowing toenails, corns and fungal nail infections with
              thorough assessment and effective, clinically proven methods.
            </p>
            <p>
              To me, nothing is more rewarding than to see the improvements my
              patients' experience in just a single visit.
            </p>
            <p>
              My aim is to give the best patient care with the most effective
              (and gentle) treatment.
            </p>
          </div>
        </StyledDiv>
        <StyledDiv>
          <div>
            <Img fluid={data.uma.childImageSharp.fluid} alt="Dr Umangi Bhatt" />
          </div>
          <div>
            <StyledH1>Dr Umangi Bhatt</StyledH1>
            <StyledH2>D.P.M - Doctor of Podiatric Medicine (UWA)</StyledH2>
            <p>
              Uma is a UWA podiatry graduate whose foundation as a medical
              scientist spiked an interest in foot care.
            </p>
            <p>
              She is passionate about podiatric treatments and management with
              experience in private practice and Perth hospitals.
            </p>
            <p>
              With a love for being at the forefront of patient care and an
              interest in translation from diagnosis to treatment, Uma strives
              to see her patients feeling better than before.
            </p>
          </div>
        </StyledDiv>
      </StyledMain>
    </Layout>
  )
}

export default OurPodiatrists
